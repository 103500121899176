import React, { Component } from "react";
import { withRouter } from "react-router";
import { Segment } from "semantic-ui-react";
import moment from 'moment';


export const SolsysAuthReactContext = React.createContext();

class SolsysAuthReact extends Component {
  state = {
    isAuthenticated: false,
    isFetchingAccessToken: false,
    isFetchingAuthorizationCode: false,
    isRefreshingToken: false,
    didAccessTokenSucceed: false,
    isFetchingUser: false,
    user: null,
  }

  reauthTimeout = null;

  apiUrl = process.env.REACT_APP_API_URL || "RUNTIME_REPLACE_REACT_APP_API_URL";

  serverUrl = process.env.REACT_APP_SOLSYSAUTH_API_URL || "RUNTIME_REPLACE_REACT_APP_SOLSYSAUTH_API_URL";

  redirectURI = process.env.REACT_APP_SOLSYSAUTH_REDIRECT_URI || "RUNTIME_REPLACE_REACT_APP_SOLSYSAUTH_REDIRECT_URI";

  clientId = process.env.REACT_APP_SOLSYSAUTH_CLIENT_ID || "RUNTIME_REPLACE_REACT_APP_SOLSYSAUTH_CLIENT_ID";

  clientSecret = process.env.REACT_APP_SOLSYSAUTH_CLIENT_SECRET || "RUNTIME_REPLACE_REACT_APP_SOLSYSAUTH_CLIENT_SECRET";

  cookieDomain = process.env.REACT_APP_SOLSYSAUTH_COOKIE_DOMAIN || "RUNTIME_REPLACE_REACT_APP_SOLSYSAUTH_COOKIE_DOMAIN";

  componentDidMount() {
    let searchParams = new URLSearchParams(this.props.location.search);

    // Checks for authorization code in redirect
    let isAuthorizationCodePresent = searchParams.has("code");
    let allCookies = document.cookie;
    let cookies = allCookies.split(';').map(v => v.trim());
    let isAuthenticated = cookies.find(cookie => cookie.startsWith("tssat_cookie_" + this.clientId));
    let accessExpiration = isAuthenticated && this.getLocalStorage('tssat_' + this.clientId);
    let refreshExpiration = this.getLocalStorage('tssrt_' + this.clientId);
    let hasReauth = refreshExpiration && new moment(refreshExpiration).isAfter();

    if(isAuthenticated) {
      // already previously authorized
      this.setState({isAuthenticated: true})
      if (!this.props.clientOnly) {
        return this.getUser();
      }
      if (hasReauth) this.setupReauth(refreshExpiration, accessExpiration);
    } else if(isAuthorizationCodePresent) {
      // get accessToken from redirect
      let code = searchParams.get("code");
      let state = searchParams.get("state");
      let stateBuffer = Buffer.from(state, 'base64');
      let jsonData = stateBuffer.toString('ascii');
      let locationState;
      try {
        locationState = JSON.parse(jsonData);
      } catch (err) {
        console.error(err);
      }
      if (locationState) this.props.history.replace(locationState.p + locationState.s + locationState.h);
      this.getAccessToken(code);
    } else if (hasReauth) {
      // get access with refresh
      this.setupReauth(refreshExpiration, accessExpiration);
    } else {
      // get an auth code
      let locationState = Buffer.from(JSON.stringify({
        p: this.props.location.pathname,
        s: this.props.location.search,
        h: this.props.location.hash
      })).toString("base64");

      if(this.props.clientOnly) {
        this.getAuthorizationCode(locationState);
      } else {
        this.sendToLogin(locationState);
      }
    }
  }

  setLocalStorage = (key, value) => {
    return localStorage.setItem(btoa(key), btoa(value));
  }

  getLocalStorage = (key) => {
    let value = localStorage.getItem(btoa(key));
    return value && atob(value);
  }

  removeLocalStorage = (key) => {
    return localStorage.removeItem(btoa(key));
  }

  sendToLogin = (locationState, logout = false) => {
    let location = `${this.serverUrl}/login?client_id=${this.clientId}&redirect_uri=${encodeURIComponent(this.redirectURI || "")}&state=${locationState}`;
    if (logout) location += "&logout=1";
    window.location = location
  }

  setupAccessToken = (accessToken, expiresIn) => {
    document.cookie = `tssat_cookie_${this.clientId}=${accessToken} ;max-age=${expiresIn} ;domain=${this.cookieDomain} ;path=/`;
    if (!this.props.clientOnly) {
      this.getUser();
    }
    this.setState({ isAuthenticated: true });
  }

  setupReauth = (expiration, accessExpiration) => {
    if (accessExpiration && new moment(accessExpiration).isAfter()) {
      if (this.reauthTimeout) clearTimeout(this.reauthTimeout);
      let timeout = new moment(accessExpiration).diff(new moment()) - 120000;
      timeout = timeout < 0 ? 0 : timeout;
      this.reauthTimeout = setTimeout(this.refreshToken, timeout);
      this.setLocalStorage('tssat_' + this.clientId, new moment(accessExpiration).format());
      this.setLocalStorage('tssrt_' + this.clientId, new moment(expiration).format());
    } else if (new moment(expiration).isAfter()) {
      this.refreshToken();
    }
  }

  getAuthorizationCode = (locationState) => {
    this.setState({isFetchingAuthorizationCode: true})
    let clientIdInput = document.createElement('input');
    clientIdInput.name = "client_id";
    clientIdInput.value = this.clientId;
    clientIdInput.type = "hidden";

    let redirectURIInput = document.createElement('input');
    redirectURIInput.name = "redirect_uri";
    redirectURIInput.value = this.redirectURI;
    redirectURIInput.type = "hidden";

    let stateInput = document.createElement('input');
    stateInput.name = "state";
    stateInput.value = locationState;
    stateInput.type = "hidden";

    let form = document.createElement('form');
    form.appendChild(clientIdInput);
    form.appendChild(redirectURIInput);
    form.appendChild(stateInput);
    form.method = "post";
    form.action = this.serverUrl + "/oauth/authorize";
    let rootDiv = document.getElementById('root');
    if (rootDiv) {
        rootDiv.append(form);
    }
    form.submit();
  }

  getAccessToken = (code) => {
    this.setState({isFetchingAccessToken: true})
    let auth = btoa(`${this.clientId}:${this.clientSecret}`);
    return fetch(this.serverUrl + "/oauth/token", {
      method: "POST",
      headers: {
        authorization: `Basic ${auth}`,
        "content-type": "application/x-www-form-urlencoded",
        clientId: this.clientId
      },
      credentials: 'include',
      body: `grant_type=authorization_code&redirect_uri=${this.redirectURI}&code=${code}`
    })
      .then(response => {
        if(response.ok) {
          return response.json()
        } else {
          throw "Token request failed"
        }
      })
      .then(json => {
        this.setupAccessToken(json.access_token, json.expires_in);
        if(json.refresh_token) {
          this.setupReauth(
            new moment().add(1209599, 'seconds'),
            new moment().add(150, 'seconds')
          )
        }
        this.setState({isFetchingAccessToken: false})
      })
      .catch(error => {
        console.error(error);
      })
  }

  refreshToken = () => {
    this.setState({isRefreshingToken: true})
    let auth = btoa(`${this.clientId}:${this.clientSecret}`);
    fetch(this.serverUrl + "/oauth/token", {
      method: "POST",
      headers: {
        authorization: `Basic ${auth}`,
        "content-type": "application/x-www-form-urlencoded",
        clientId: this.clientId
      },
      credentials: 'include',
      body: `grant_type=refresh_token&redirect_uri=${this.redirectURI}`,
    })
      .then(response => {
        if(response.ok) {
          return response.json()
        } else {
          console.log("Refresh Token request failed");
          this.logout();
        }
      })
      .then(json => {
        if(json) {
          this.setupAccessToken(json.access_token, json.expires_in);
          if(json.refresh_token) {
            this.setupReauth(
              new moment().add(1209599, 'seconds'),
              new moment().add(json.expires_in, 'seconds')
            )
          }
        }
        this.setState({isRefreshingToken: false})
      })
  }

  getUser = () => {
    this.setState({isFetchingUser: true});
    return fetch(this.apiUrl + "/user", {
      method: "GET",
      headers: { clientId: this.clientId },
      credentials: 'include'
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw response;
        }
      })
      .then(json => {
        this.setState({user: json})
      })
      .catch(error => {
        console.error(error);
        alert('Retrieving your user info failed, see the browser log for details');
      })
      .finally(() => {
        this.setState({isFetchingUser: false})
      })
  }

  logout = () => {
    document.cookie = `tssat_cookie_${this.clientId}=expired ;max-age=0 ;domain=${this.cookieDomain} ;path=/`;
    this.removeLocalStorage('tssat_' + this.clientId);
    this.removeLocalStorage('tssrt_' + this.clientId);
    if(!this.props.clientOnly) {
      let locationState = Buffer.from(JSON.stringify({
        p: this.props.location.pathname,
        s: this.props.location.search,
        h: this.props.location.hash
      })).toString("base64");
      this.sendToLogin(locationState, true);
    }
  }

  render() {
    let renderChildren = !this.state.isFetchingAccessToken &&
      !this.state.isFetchingAuthorizationCode &&
      this.state.isAuthenticated &&
      (this.props.clientOnly || this.state.user !== null);

    return (
      <SolsysAuthReactContext.Provider
        value={{
          logout: this.logout,
          user: this.state.user,
        }}
      >
        {renderChildren ? this.props.children : <Segment basic loading padded="very"/>}
      </SolsysAuthReactContext.Provider>
    )
  }
};

export default withRouter(SolsysAuthReact);
